import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import NodeBackground from './components/NodeBackground';

function HomePage() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoplayPaused, setAutoplayPaused] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactForm, setContactForm] = useState({ name: '', email: '', message: '' });

  const slides = [
    { id: 1, text: 'Image 1' },
    { id: 2, text: 'Image 2' },
    { id: 3, text: 'Image 3' },
  ];

  const resetAutoplayTimer = useCallback(() => {
    setAutoplayPaused(true);
    setTimeout(() => setAutoplayPaused(false), 6000);
  }, []);

  useEffect(() => {
    let timer;
    if (!autoplayPaused) {
      timer = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 3000);
    }

    return () => clearInterval(timer);
  }, [autoplayPaused, slides.length]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    resetAutoplayTimer();
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    resetAutoplayTimer();
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    resetAutoplayTimer();
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', contactForm);
    setContactForm({ name: '', email: '', message: '' });
    setShowContactModal(false);
  };

  return (
    <div className="min-h-screen w-full bg-gray-900 text-gray-100 flex flex-col justify-center items-center relative overflow-hidden">
      <NodeBackground />
      
      <div className="z-10 flex flex-col items-center w-full max-w-5xl px-4 py-12">
        <h1 className="text-6xl font-bold mb-4">
          <span className="shine">juppiter</span>
        </h1>
        <p className="text-2xl mb-8">Financial market intelligence at your fingertips.</p>
        <Link 
          to="" 
          className="bg-[#2196f3] hover:bg-[#1e88e5] text-white font-bold text-xl py-3 px-6 rounded-lg transition duration-300 mb-12 transform hover:scale-105 shadow-lg"
        >
          Launch App
        </Link>
        
        {/* Carousel */}
        <div className="w-full bg-gray-800 p-4 rounded-lg shadow-lg mb-12">
          <div className="carousel-container h-[600px] bg-gray-700 rounded relative overflow-hidden">
            {slides.map((slide, index) => (
              <div
                key={slide.id}
                className={`absolute top-0 left-0 w-full h-full flex items-center justify-center transition-opacity duration-500 ${
                  index === currentSlide ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <p className="text-2xl text-gray-400">{slide.text}</p>
              </div>
            ))}
            <button 
              onClick={prevSlide} 
              className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
            >
              <ChevronLeft size={24} />
            </button>
            <button 
              onClick={nextSlide} 
              className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
            >
              <ChevronRight size={24} />
            </button>
          </div>
          <div className="flex justify-center mt-4">
            {slides.map((slide, index) => (
              <button
                key={slide.id}
                onClick={() => goToSlide(index)}
                className={`w-3 h-3 rounded-full mx-1 transition-all duration-300 ${
                  index === currentSlide ? 'bg-blue-500 scale-125' : 'bg-gray-500 hover:bg-gray-400'
                }`}
              ></button>
            ))}
          </div>
        </div>

        {/* Footer Links */}
        <div className="flex space-x-8 text-lg">
          <Link to="" className="text-blue-400 hover:text-blue-300 transition duration-300 footer-link">App</Link>
          <button onClick={() => setShowAboutModal(true)} className="text-blue-400 hover:text-blue-300 transition duration-300 footer-link">About</button>
          <button onClick={() => setShowContactModal(true)} className="text-blue-400 hover:text-blue-300 transition duration-300 footer-link">Contact</button>
        </div>

        {/* About Modal */}
        {showAboutModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-gray-800 p-8 rounded-lg max-w-2xl">
              <h2 className="text-2xl font-bold mb-4">About Juppiter</h2>
              <p className="mb-4">
                Juppiter was founded in 2023 with the mission to democratize access to financial market insights. 
              </p>
              <p className="mb-4">
                Since our inception, we've been committed to empowering individuals and businesses 
                with the tools they need to make informed decisions in an increasingly complex global economy.
              </p>
              <button 
                onClick={() => setShowAboutModal(false)}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </div>
        )}

        {/* Contact Modal */}
        {showContactModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-gray-800 p-8 rounded-lg max-w-md w-full">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Contact Us</h2>
                <button onClick={() => setShowContactModal(false)} className="text-gray-400 hover:text-white">
                  <X size={24} />
                </button>
              </div>
              <form onSubmit={handleContactSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">Name</label>
                  <input
                    type="text"
                    id="name"
                    value={contactForm.name}
                    onChange={(e) => setContactForm({...contactForm, name: e.target.value})}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email</label>
                  <input
                    type="email"
                    id="email"
                    value={contactForm.email}
                    onChange={(e) => setContactForm({...contactForm, email: e.target.value})}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">Message</label>
                  <textarea
                    id="message"
                    value={contactForm.message}
                    onChange={(e) => setContactForm({...contactForm, message: e.target.value})}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                    rows="4"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        )}

        <style jsx>{`
          @keyframes shine {
            0% { background-position: -200px; }
            100% { background-position: 200px; }
          }
          .shine {
            background: linear-gradient(90deg, #2196f3 0%, #ffffff 50%, #2196f3 100%);
            background-size: 400px 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: shine 8s infinite linear;
          }
          .footer-link {
            position: relative;
          }
          .footer-link::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #60a5fa;
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.3s ease-out;
          }
          .footer-link:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        `}</style>
      </div>
    </div>
  );
}

export default HomePage;
